import { AuthPage } from 'pages/auth';
import { ChannelPage } from 'pages/channel';
import { CreateChannelPage } from 'pages/create-channel';
import { EditChannelPage } from 'pages/edit-channel';
import { EmployeeManagementPage } from 'pages/employee-management';
import { HomePage } from 'pages/home';
import { NotFoundPage } from 'pages/not-found';
import { RoleManagementPage } from 'pages/role-management';
import { TelegramAuthPage } from 'pages/telegram-auth';
import { DocumentationPage } from 'pages/documentation';
import {
    EAppRoutes,
    RoutePath,
} from 'shared/constants/router';
import { TRoutePropsWithAdditionalSettings } from 'shared/types/router';
import { WebappAuthPage } from 'pages/webapp-auth';
import { ERightsUser } from 'shared/types/user';

export const routerConfig: Record<EAppRoutes, TRoutePropsWithAdditionalSettings> = {
    [EAppRoutes.MAIN]: {
        element: <HomePage />,
        isTransparentHeader: false,
        path: RoutePath.main,
        withAuth: true,
    },
    [EAppRoutes.CHANNEL]: {
        element: <ChannelPage />,
        isTransparentHeader: false,
        path: RoutePath.channel,
        withAuth: true,
    },
    [EAppRoutes.AUTH]: {
        element: <AuthPage />,
        isTransparentHeader: true,
        path: RoutePath.auth,
        withAuth: false,
    },
    [EAppRoutes.CREATE_CHANNEL]: {
        accessRight: ERightsUser.IS_CHANNEL_EDITION,
        element: <CreateChannelPage />,
        isTransparentHeader: false,
        path: RoutePath.create_channel,
        withAuth: true,
    },
    [EAppRoutes.EDIT_CHANNEL]: {
        accessRight: ERightsUser.IS_CHANNEL_EDITION,
        element: <EditChannelPage />,
        isTransparentHeader: false,
        path: RoutePath.edit_channel,
        withAuth: true,
    },
    [EAppRoutes.TELEGRAM_AUTH]: {
        element: <TelegramAuthPage />,
        isTransparentHeader: true,
        path: RoutePath.telegram_auth,
        withAuth: false,
    },
    [EAppRoutes.WEBAPP_AUTH]: {
        element: <WebappAuthPage />,
        isTransparentHeader: true,
        path: RoutePath.webapp_auth,
        withAuth: false,
    },
    [EAppRoutes.EMPLOYEE_MANAGEMENT]: {
        element: <EmployeeManagementPage />,
        isTransparentHeader: false,
        path: RoutePath.employee_management,
        withAuth: true,
    },
    [EAppRoutes.ROLES_MANAGEMENT]: {
        accessRight: ERightsUser.IS_ROLE_EDITION,
        element: <RoleManagementPage />,
        isTransparentHeader: false,
        path: RoutePath.roles_management,
        withAuth: true,
    },
    [EAppRoutes.DOCUMENTATION]: {
        element: <DocumentationPage />,
        isTransparentHeader: false,
        path: RoutePath.documentation,
        withAuth: true,
    },
    [EAppRoutes.NOT_FOUND]: {
        element: <NotFoundPage />,
        isTransparentHeader: true,
        path: RoutePath.not_found,
        withAuth: false,
    },
};
