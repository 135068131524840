import { IEmployee } from 'entities/employee';
import { IChannel } from 'shared/types/channels';

export interface IRole {
    id: string;
    name: string;
    users?: IEmployee[];
    channels?: IChannel[];
    accessRights?: IAccessRights;
    default?: boolean;
    noRightsRole?: boolean;
    usersCount: number;
    channelsCount: number;
}

export interface IRoleFromServer {
    id: string;
    name: string;
    users?: IEmployee[];
    channels?: IChannel[];
    access_rights?: IAccessRightsFromServer;
    default?: boolean;
    no_rights_role?: boolean;
    users_count: number;
    channels_count: number;
}

export interface IAccessRightsAdminModesFromServer {
    invitation_access: boolean;
    role_edition: boolean;
    role_assignment: boolean;
    channel_spectation: boolean;
}

export interface IAccessRightsAdminModes {
    invitationAccess: boolean;
    roleEdition: boolean;
    roleAssignment: boolean;
    channelSpectation: boolean;
}

export interface IAccessRightsChannelManagementFromServer {
    post_edition: boolean;
    channel_settings_edition: boolean;
}

export interface IAccessRightsChannelManagement {
    postEdition: boolean;
    channelSettingsEdition: boolean;
}

export interface IAccessRightsFromServer {
    admin_modes: IAccessRightsAdminModesFromServer;
    channel_management: IAccessRightsChannelManagementFromServer;
}

export interface IAccessRights {
    adminModes: IAccessRightsAdminModes;
    channelManagement: IAccessRightsChannelManagement;
}

export enum EValueMainAllCheckbox {
    ON = 'on',
    OFF = 'off',
}
