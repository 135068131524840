import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { ISidePageSchema } from '../typings/side-page.types';

const initialState: ISidePageSchema = {
    isOpen: false,
    sidePages: [],
};

export const sidePageSlice = createSlice({
    initialState,
    name: 'sidePage',
    reducers: {
        addSidePage(state, { payload }: PayloadAction<{ name: string; zIndex: number }>) {
            const isInState = state.sidePages.find((el) => el.name === payload.name);
            if (!isInState) {
                state.sidePages.push({
                    name: payload.name,
                    zIndex: payload.zIndex,
                });
            }
            state.isOpen = true;
        },
        deleteSidePage(state, { payload }: PayloadAction<string>) {
            const arr = state.sidePages.filter((el) => el.name !== payload);
            state.sidePages = arr;

            if (arr.length === 0) {
                state.isOpen = false;
            }
        },

    },
});

export const { reducer: sidePageReducer } = sidePageSlice;
export const { actions: sidePageActions } = sidePageSlice;
