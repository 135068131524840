import { IBackendListResponse } from 'shared/types/types';

export const transformApiListResponse = async <T, U> (rawResult:
IBackendListResponse<T>, mapFunction: (item: T) => U) => ({
    checkedCount: rawResult.checked_count,
    count: rawResult.count,
    next: rawResult.next,
    previous: rawResult.previous,
    results: rawResult.results.map(mapFunction),
    totalCount: rawResult.total_count,
});
