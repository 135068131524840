import {
    IRole,
    IRoleFromServer,
} from 'entities/role';
import { transformRoleAccessRights } from 'features/edit-role';

export const transformRole = (rawResult: IRoleFromServer): IRole => ({
    accessRights: rawResult.access_rights ? transformRoleAccessRights(rawResult.access_rights) : undefined,
    channels: rawResult.channels,
    channelsCount: rawResult.channels_count || 0,
    default: rawResult.default,
    id: rawResult.id,
    name: rawResult.name,
    noRightsRole: rawResult.no_rights_role,
    users: rawResult.users,
    usersCount: rawResult.users_count || 0,
});

export const transformRoleList = async (rawResult:
IRoleFromServer[]) => rawResult.map((item) => transformRole(item));
