import {
    FC,
    ReactNode,
    memo,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getUserAuth } from 'entities/user';
import { LogoIcon } from 'shared/assets/components/LogoIcon';
import {
    AUTH_URL,
    HOME_URL,
} from 'shared/constants/router';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { Text } from 'shared/ui/text/Text';

import cls from './DynamicHeader.module.scss';

export interface IDynamicHeaderProps {
    className?: string;
    isTransparent?: boolean;
    leftBlock?: ReactNode;
    rightBlock?: ReactNode;
    title?: string;
    isHideCenterBlock?: boolean;
    centerBlock?: ReactNode;
    isFixedHeader?: boolean;
    isShowLoadingLine?: boolean;
    widthLoadingLine?: number;
}
export const DynamicHeader: FC<IDynamicHeaderProps> = memo((props) => {
    const {
        centerBlock,
        className = '',
        isFixedHeader,
        isHideCenterBlock = false,
        isShowLoadingLine = false,
        isTransparent,
        leftBlock = null,
        rightBlock = null,
        title,
        widthLoadingLine,
    } = props;

    const userAuth = useSelector(getUserAuth);

    if (isTransparent) {
        return (
            <header className={cls.headerTransparent}>
                <Link to={userAuth ? HOME_URL : AUTH_URL}>
                    <LogoIcon className={cls.logoDark} />
                </Link>
            </header>
        );
    }

    const centerBlockElement = useMemo(() => {
        if (isHideCenterBlock) {
            return null;
        }
        if (title) {
            return <Text text={title} as="h2" size="md" weight="600" className={cls.title} />;
        }
        if (centerBlock) {
            return centerBlock;
        }
        return (
            <Link to={HOME_URL}>
                <LogoIcon className={cls.logoDark} />
            </Link>
        );
    }, [title, isHideCenterBlock]);

    return (
        <>
            <header className={classNames(
                cls.header,
                {
                    [cls.hiddenCenterBlock]: isHideCenterBlock,
                    [cls.fixedHeader]: isFixedHeader,
                },
                [className],
            )}
            >
                <div className={cls.leftBlock}>{leftBlock}</div>
                <div className={cls.centerBlock}>{centerBlockElement}</div>
                <div className={cls.rightBlock}>{rightBlock}</div>

                {isShowLoadingLine && widthLoadingLine && (
                    <div className={cls.holerLine}>
                        <div className={cls.line} style={{ width: `${widthLoadingLine}%` }} />
                    </div>
                )}
            </header>
            {isFixedHeader && <div className={cls.hiddenBlock} />}
        </>

    );
});
